























import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Profile extends Vue {
  links = [
    {
      icon: "envelope",
      url: "mailto:contact@davidsuarez.dev",
      title: "Email"
    },
    {
      icon: ["fab", "github-alt"],
      url: "https://github.com/davidsuarezcdo",
      title: "Github"
    },
    {
      icon: ["fab", "linkedin-in"],
      url: "https://www.linkedin.com/in/davidsuarezcdo/",
      title: "Linkedin"
    },
    {
      icon: ["fab", "steam"],
      url: "https://steamcommunity.com/id/david_srz/",
      title: "Steam"
    },
    {
      icon: ["fab", "spotify"],
      url: "https://open.spotify.com/user/12151888949",
      title: "Spotify"
    }
  ];
}
