






















import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Navbar extends Vue {
  languages = [
    { title: "English", value: "en" },
    { title: "Español", value: "es" }
  ];

  goTop() {
    window.scrollTo(0, 0);
  }

  isActive(language: string): boolean {
    let [current] = this.$i18n.locale.split("-");
    return language == current;
  }

  changeLanguage(event: any) {
    let value = event.target.dataset.value;
    this.$i18n.locale = value;
  }
}
