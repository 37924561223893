










































import SkillIcon from "./SkillIcon.vue";
import { Component, Vue } from "vue-property-decorator";
import { DateTime } from "luxon";

interface iProject {
  logo: string;
  name: string;
  role: string;
  description: string;
  years: string[];
  technologies: string[];
  links: {
    url: string;
    icon: string;
    title: string;
  }[];
}

@Component({
  components: {
    SkillIcon
  }
})
export default class Projects extends Vue {
  public items: iProject[] = [];

  async mounted() {
    this.items = await require("../data/projects.json");
  }

  getImgUrl(image: string): string {
    const images = require.context("../assets/images/projects", false, /\.(png|jpe?g)$/);
    return images(`./${image}`);
  }

  timePeriod(times: string[]): string {
    const format = "MMM yyyy";
    let [from, until = DateTime.now()] = times.map(i => DateTime.fromFormat(i, format));

    let months = Math.trunc(until.diff(from, "months").get("months"));
    let years = Math.trunc(until.diff(from, "years").get("years"));

    function formatDate(date: DateTime): string {
      return date
        .setLocale("es")
        .setZone("America/Mexico_City")
        .toFormat(format)
        .capitalize();
    }

    let period = `${formatDate(from)} - ${formatDate(until)}`;
    if (until.toISODate() == DateTime.now().toISODate()) {
      period = `${formatDate(from)} - ${this.$i18n.t("now")}`;
    }
    if (years > 0) {
      return `${period} (${years} ${this.$i18n.tc("years", years)})`;
    } else if (months > 0) {
      return `${period} (${months} ${this.$i18n.tc("months", months)})`;
    }
    return period;
  }
}
